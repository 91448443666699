// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beint-tsx": () => import("./../src/pages/beint.tsx" /* webpackChunkName: "component---src-pages-beint-tsx" */),
  "component---src-pages-dagskra-tsx": () => import("./../src/pages/dagskra.tsx" /* webpackChunkName: "component---src-pages-dagskra-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-itarefni-tsx": () => import("./../src/pages/itarefni.tsx" /* webpackChunkName: "component---src-pages-itarefni-tsx" */),
  "component---src-pages-naermynd-tsx": () => import("./../src/pages/naermynd.tsx" /* webpackChunkName: "component---src-pages-naermynd-tsx" */),
  "component---src-pages-upptokur-tsx": () => import("./../src/pages/upptokur.tsx" /* webpackChunkName: "component---src-pages-upptokur-tsx" */),
  "component---src-templates-event-tsx": () => import("./../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */)
}

